<template>
  <RevenueIndex
    :fst-id="fstId"
    :qso="qso"
    :endpoint="endpoint"
    :filterItems="filterOptions"
  />
</template>

<script>
import RevenueIndex from '@/composites/transaction/revenue/index/Index.vue'
import { useEndpoints } from '@/composables'
export default {
  name: 'ViewOrganizationUserRevenues',

  components: {
    RevenueIndex,
  },
  data() {
    return {
      fstId: null,
      qso: { append: '', prepend: '' },
      endpoint: null,
      filterOptions: [],
    }
  },
  async created() {
    this.fstId = `franchise-revenues`
    this.generateEndPoint()
    await this.generateFilterOptions()
  },

  methods: {
    generateEndPoint() {
      this.endpoint =
        useEndpoints.invoice.indexOfRevenue() +
        `franchise_user=${this.$route.params.id}&`
    },
    async generateFilterOptions() {
      const filterableFleets = []
      const url = useEndpoints.fleet.indexOfStaff(this.$route.params.id)
      await this.$http.get(url).then(({ data }) => {
        if (data.data.length) {
          data.data.forEach((fleet) => {
            filterableFleets.push({ text: fleet.name, value: fleet.id })
          })
        }
      })
      this.filterOptions = [
        {
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        },
        {
          key: 'payment_source',
          type: 'checkbox',
          input: [
            { text: 'Card', value: 'CARD' },
            { text: 'Trip', value: 'TRIP' },
            { text: 'Recharge', value: 'RECHARGE' },
            { text: 'Sign Up Bonus', value: 'SIGN_UP_BONUS' },
            { text: 'Referral Bonus', value: 'REFERRAL_BONUS' },
            { text: 'Balance Adjustment', value: 'BALANCE_ADJUSTMENT' },
          ],

          title: 'Source',
        },
        {
          type: 'radio',
          key: 'status',
          input: [
            { text: 'Pending', value: 'P' },
            { text: 'Completed', value: 'C' },
          ],
          title: 'Status',
        },
        {
          key: 'dispute_refund', // global key of the filter (essentially the query param)
          type: 'checkbox-keyed', // global key won't be used, in that case set indivisual key with input
          input: [
            {
              key: 'is_disputed',
              text: 'Disputed',
              checkedValue: 'true', // checked value will be used in filter
              uncheckedValue: 'false', // default value & will be ignored
            },
            {
              key: 'is_refunded',
              text: 'Refunded',
              checkedValue: 'true', // checked value will be used in filter
              uncheckedValue: 'false', // default value & will be ignored
            },
          ],

          title: 'Billing',
        },
        {
          key: 'test_range',
          type: 'range',
          input: {
            value: [0, 0], // initial value of model -> [min, max]
            unit: this.symbol,
            max: 9999,
            minQs: 'min_amount', // query string for min value
            maxQs: 'max_amount', // query string for max value
          },
          title: 'Amount',
        },
      ]
    },
  },
}
</script>
